<template>
  <relationship-with-sun-template
    :always-uses-sunscreen="alwaysUsesSunscreen"
    :currently-tanned="currentlyTanned"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import RelationshipWithSunTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/relationship-with-sun/RelationshipWithSunTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('alwaysUsesSunscreen'), requiredField('currentlyTanned')];

export default {
  name: 'RelationshipWithSun',
  components: {
    RelationshipWithSunTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    alwaysUsesSunscreen(newValue, oldValue) {
      if (!oldValue && this.currentlyTanned) {
        this.showNextStep();
      } else {
        this.scrollTo('#tanned-body');
      }
    },
    currentlyTanned(newValue, oldValue) {
      if (!oldValue && this.alwaysUsesSunscreen) {
        this.showNextStep();
      }
    }
  }
};
</script>
